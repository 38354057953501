import React, { useEffect, useState } from "react";
import CanvasImg from "../assest/image/canvas.png";

const Home = () => {
  const [activeBtnDs, setActiveBtnDs] = useState("");
  const [activeBtnCs, setActiveBtnCs] = useState("");
  const [activeSize, setActiveSize] = useState("");
  // const [theme, setTheme] = useState("light");

  const handleDsClick = (frameClass) => {
    setActiveBtnDs(frameClass);
    setActiveBtnCs(""); // Reset CS buttons
  };

  const handleCsClick = (frameClass) => {
    setActiveBtnCs(frameClass);
    setActiveBtnDs(""); // Reset DS buttons
  };

  const [theme, setTheme] = useState(() => {
    // Retrieve theme from localStorage if available, default to 'light' if not set
    return localStorage.getItem("theme") || "light";
  });

  useEffect(() => {
    // Update iframe src based on theme change
    if (theme === "light") {
      document.querySelector(".getFrame").src =
        "https://up2client.com/envato/amigo-chatgpt-pwa/main-file/index.html";
    } else {
      document.querySelector(".getFrame").src =
        "https://up2client.com/envato/amigo-chatgpt-pwa/main-file/index.html";
    }
    // Save current theme to localStorage
    localStorage.setItem("theme", theme);
  }, [theme]);
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  return (
    <>
      {/* <!-- Preview page Details Start  --> */}
      <section id="preview-page-sec">
        <div class="mobile-app-area" id="particles-snow">
          <div class="container">
            <div class="row align-items-center main-page-class">
              <div
                class="col-md-12 col-lg-7 text-lg-left"
                data-aos="fade-right"
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
              >
                <h1 class="main-title">Amigo GPT AI Chatbot Mobile App</h1>
                <div>
                  <label class="switch">
                    <span
                      class="mode"
                      onclick="myFunction()"
                      title="Toggle Mode"
                    >
                      <input id="toggle" type="checkbox" />
                      <span class="slider"></span>
                    </span>
                  </label>
                  <div class="slide-block slide-out"></div>
                </div>
                <ul class="point-list">
                  <li>PWA Ready</li>
                  <li>Trendy Design </li>
                  <li>Sidebar Menu</li>
                  <li>Dark And Light Mode</li>
                  <li>100+ Screen</li>
                  <li>Bootstrap ready</li>
                  <li>Fully Responsive</li>
                  <li>Well Documentation</li>
                </ul>
                <div class="preview-page-btn">
                  <div class=" pre-btn mt-50">
                    <a
                      href="https://1.envato.market/website-portfolio"
                      target="_blank"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div class="pre-btn pre-btn1 mt-50">
                    <a href="../main-file/index.html" target="_blank">
                      Click To View
                    </a>
                  </div>
                </div>
                <div class="scan-area">
                  <div class="scan">
                    <img src={CanvasImg} alt="canvas" />
                  </div>
                  <p>
                    Scan to view on <br /> your mobile device
                  </p>
                </div>
              </div>
              <div class="col-md-12 col-lg-5 phoneContent-main">
                <div class="phoneContent">
                  <div class="ripple-1"></div>
                  <div class="ripple-2"></div>
                  <div class="ripple-3"></div>
                  <div class="ripple-4"></div>
                  <div class="ripple-5"></div>
                  <div className={`volume style-2 ${activeSize}`}></div>
                  <div className={`switch style-2 ${activeSize}`}></div>
                  <div className={`phoneWrapper ${activeBtnDs} ${activeBtnCs}`}>
                    <div className="in">
                      <iframe
                        className="getFrame"
                        src="../main-file/index.html"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div class="device-setting-sec">
                  <div class="main-list">
                    <h2 class="d-none">Hidden</h2>
                    <h3 class="d-none">Hidden</h3>
                    <h4 class="title-device">Device Settings</h4>
                    <ul class="size-list">
                      <li className={activeBtnDs === "" ? "active" : ""}>
                        <a
                          class="btn-ds"
                          href=""
                          data-frame=""
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("");
                          }}
                        >
                          <svg
                            width="24"
                            height="40"
                            viewBox="0 0 24 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="22"
                              height="38"
                              rx="5"
                              fill="white"
                              stroke="#666666"
                              stroke-width="2"
                            ></rect>
                          </svg>
                          Normal
                        </a>
                      </li>
                      <li
                        className={activeBtnDs === "frame-lg" ? "active" : ""}
                      >
                        <a
                          class="btn-ds"
                          href=""
                          data-frame="frame-lg"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("frame-lg");
                          }}
                        >
                          <svg
                            width="30"
                            height="50"
                            viewBox="0 0 30 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="28"
                              height="48"
                              rx="5"
                              fill="white"
                              stroke="#666666"
                              stroke-width="2"
                            />
                          </svg>
                          Large
                        </a>
                      </li>
                      <li
                        className={
                          activeBtnDs === "frame-rounded" ? "active" : ""
                        }
                      >
                        <a
                          class="btn-cs"
                          href=""
                          data-frame="frame-rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("frame-rounded");
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 9.67539V7C1 3.6863 3.68629 1 7 1H9.67539"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M1 22.3246V25C1 28.3137 3.68629 31 7 31H9.67539"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M31 9.67539V7C31 3.6863 28.3137 1 25 1H22.3246"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M31 22.3246V25C31 28.3137 28.3137 31 25 31H22.3246"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                          </svg>
                          Rounded
                        </a>
                      </li>
                      <li
                        className={
                          activeBtnDs === "frame-solid" ? "active" : ""
                        }
                      >
                        <a
                          class="btn-cs"
                          href=""
                          data-frame="frame-solid"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDsClick("frame-solid");
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 9.67539V1H9.67539"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M1 22.3246V31H9.67539"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M31 9.67539V1H22.3246"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M31 22.3246V31H22.3246"
                              stroke="#666666"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                          </svg>
                          Squared
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Preview page Details End  --> */}
    </>
  );
};
export default Home;
