import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- Footer Section Start  --> */}
      <footer id="copyright-sec">
        <div class="container">
          <div class="footer-line">
            <p class="copyright-txt">
              Amigo GPT AI Chatbot © Copyright by
              <span class="footer-txt2">
                <a
                  href="https://1.envato.market/website-portfolio"
                  target="_blank"
                >
                  The_Krishna
                </a>
              </span>
            </p>
          </div>
        </div>
      </footer>
      {/* <!-- Footer Section End  --> */}
    </>
  );
};
export default Footer;
